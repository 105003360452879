var render = function render(){
  var _vm$fileTypes;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.existingFileName ? _c('div', {
    staticClass: "d-flex align-items-center flex-grow-1 p-3 FileUpload FileUpload-readonly"
  }, [_c('i', {
    class: `fad fa-fw fa-2x mr-3 ${_vm.status.icon}`
  }), _c('div', {
    staticClass: "text-break flex-grow-1 font-w600 mb-0"
  }, [_c('span', {
    class: _vm.status.class
  }, [_vm._v(_vm._s(_vm.status.text))])]), !_vm.readOnly ? _c('button', {
    staticClass: "btn btn-link text-danger fa-xl",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.reset.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times me-1",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()]) : _c('div', [_c('div', {
    staticClass: "d-flex align-items-center flex-grow-1 p-3 FileUpload",
    class: {
      'FileUpload-drag': _vm.isDrag
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.fileInput.click();
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onFileDrop.apply(null, arguments);
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        return _vm.onFileStartDrag.apply(null, arguments);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        return _vm.onFileStartDrag.apply(null, arguments);
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        return _vm.onFileStopDrag.apply(null, arguments);
      }
    }
  }, [_c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": _vm.name,
      "type": "file",
      "name": _vm.name,
      "accept": ((_vm$fileTypes = _vm.fileTypes) === null || _vm$fileTypes === void 0 ? void 0 : _vm$fileTypes.length) > 0 ? _vm.fileTypes.map(function (type) {
        return `.${type}`;
      }).join(', ') : null,
      "enctype": "multipart/form-data",
      "multiple": _vm.multipleFiles
    },
    on: {
      "change": function (e) {
        return _vm.onFileSelect(e.target);
      }
    }
  }), _c('i', {
    class: `fad fa-fw fa-2x mr-3 ${_vm.status.icon}`
  }), _c('div', {
    staticClass: "text-break flex-grow-1 font-w600 mb-0"
  }, [_vm.isDrag ? _c('span', [_vm._v("Drop file here")]) : _c('span', {
    class: _vm.status.class
  }, [_vm._v(_vm._s(_vm.status.text))])]), _vm.progress !== null && _vm.loading ? _c('div', {
    staticClass: "FileUpload-progress"
  }, [_c('div', {
    staticClass: "FileUpload-progress-bar",
    class: {
      'FileUpload-progress-bar--complete': _vm.progress === 100
    },
    style: `width: ${_vm.progress}%`
  })]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }